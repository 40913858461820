<!--
 * @Author: wxb
 * @Date: 2021-06-15 23:40:53
 * @LastEditTime: 2021-08-10 23:50:50
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Login\index.vue
-->
<template>
  <div class="containner">
    <div class="login-solgan">度你金针一枚 绣出商机无限</div>
    <div class="login-content">
      <div class="login-content-img"></div>
      <div class="login-content-info">
        <div class="login-content-info-toggle">
          <div class="login-content-info-toggle-title">
            {{loginTypeMsg}}
          </div>
          <div class="login-content-info-toggle-btn">
            <a @click="toggleLoginType">
              <img :src="loginType==='account' ? qrlogin : accountlogin">
            </a>
          </div>
        </div>
        <div class="login-content-info-form"
             v-show="loginType==='account'">
          <el-form ref="loginForm"
                   :model="form"
                   :rules="loginRules"
                   @submit.native.prevent>
            <el-form-item prop="userName">
              <el-input v-model="form.userName"
                        placeholder="请输入用户名"
                        @keyup.enter.native="handleLogin">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="form.password"
                        placeholder="请输入密码"
                        type="password"
                        @keyup.enter.native="handleLogin">
              </el-input>
            </el-form-item>
            <!-- <div class="login-content-info-form-tools">
              <div class="login-content-info-form-tools-rember">
                <el-checkbox v-model="checked">记住用户名</el-checkbox>
              </div>
              <div class="login-content-info-form-tools-btns">
                <a>立即注册</a><span>|</span><a>忘记密码</a>
              </div>
            </div> -->

            <div class="login-btn-submit"
                 @click="handleLogin">登录</div>
          </el-form>
        </div>
        <div class="login-content-info-qrcode"
             v-show="loginType==='qrcode'">
          <div class="login-content-info-qrcode-info">
            <div class="qrcode-refresh"
                 v-show="isExpire"
                 @click="handleFreshQrCode">
              <img class="refresh"
                   src="../../assets/Login/refresh.png">
              <div class="qrcode-refresh-title">二维码失效，点击刷新</div>
            </div>
            <img :src="qrCodeUrl">
            <div class="login-content-info-qrcode-info-title">请使用微信扫描二维码关注并登录“度金针”</div>
            <div class="login-content-info-qrcode-info-btn">
              <a @click="toggleLoginType">使用密码登录</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setToken, setUserInfo } from '@/utils/auth'
import { loginByUsername, getQrCode, checkLogin } from '@/api/login'
export default {
  name: 'login',
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      loginTypeMsg: '微信二维码登录',
      loginType: 'qrcode',
      qrlogin: require('@/assets/Login/qrlogin.png'),
      accountlogin: require('@/assets/Login/accountlogin.png'),
      form: {
        userName: '',
        password: ''
      },
      loginRules: {
        userName: [{
          required: true,
          message: '请输入用户名',
          trigger: ['blur', 'change']
        },
        {
          min: 3,
          max: 20,
          message: '长度在3到20个字符',
          trigger: ['blur', 'change']
        }],
        password: [{
          required: true,
          trigger: ['blur', 'change'],
          validator: validatePass
        }]
      },
      checked: false,
      ticket: '',
      scene_str: '',
      invalidTime: '',
      expire_seconds: '',
      qrCodeUrl: null,
      timeObj: null,
      isExpire: false
    }
  },
  methods: {
    // 账号密码登录
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          loginByUsername(this.form.userName, this.form.password).then(response => {
            const data = response
            if (data.access_token === '' || data.access_token === undefined) {
              this.$message({
                message: '账户或密码错误！',
                type: 'warning'
              })
            } else {
              if (this.timeObj) {
                clearTimeout(this.timeObj)
              }
              const token = 'Bearer ' + data.access_token
              const sourceurl = localStorage.getItem('sourceurl') || '/'
              setToken(token)
              this.$store.dispatch('setToken', token)
              this.$store.dispatch('getUserInfo').then(info => { // 拉取user_info
                setUserInfo(info)
                const elements = {}
                for (let i = 0; i < info.elements.length; i++) {
                  elements[info.elements[i].code] = true
                }
                localStorage.setItem('elements', JSON.stringify(elements))
                this.$router.push({
                  path: sourceurl
                })
              }).catch(() => {
                this.$router.push({
                  path: sourceurl
                })
              })
            }
          }).catch(error => {
            console.log(error)
            this.$message({
              message: '账户或密码错误！',
              type: 'warning'
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toggleLoginType() {
      if (this.loginType === 'account') {
        this.loginType = 'qrcode'
        this.loginTypeMsg = '微信二维码登录'
        this.initQrCode()
      } else {
        this.loginType = 'account'
        this.loginTypeMsg = '密码登录'
      }
    },
    handleFreshQrCode() {
      this.initQrCode()
    },
    // 初始化二维码
    initQrCode() {
      getQrCode().then(res => {
        if (res.ticket) {
          this.isExpire = false
          this.ticket = res.ticket
          this.expire_seconds = res.expire_seconds
          this.invalidTime = res.invalidTime
          this.scene_str = res.scene_str
          this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${res.ticket}`
          this.longPoll()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 长轮询
    longPoll() {
      const that = this
      const now = new Date()
      if (now > new Date(this.invalidTime)) {
        if (that.timeObj) {
          clearTimeout(that.timeObj)
        }
        this.isExpire = true
        return
      }
      if (that.timeObj) {
        clearTimeout(that.timeObj)
      }
      const params = {
        scene_str: that.scene_str
      }
      checkLogin(params).then(res => {
        if (res.userName) {
          // const password = res.password
          // console.log(password)
          loginByUsername(res.userName, res.userName).then(response => {
            const data = response
            if (data.access_token === '' || data.access_token === undefined) {
              this.$message({
                message: '授权登录失败！',
                type: 'warning'
              })
            } else {
              const token = 'Bearer ' + data.access_token
              const sourceurl = localStorage.getItem('sourceurl') || '/'
              setToken(token)
              this.$store.dispatch('setToken', token)
              this.$store.dispatch('getUserInfo').then(info => { // 拉取user_info
                setUserInfo(info)
                const elements = {}
                for (let i = 0; i < info.elements.length; i++) {
                  elements[info.elements[i].code] = true
                }
                localStorage.setItem('elements', JSON.stringify(elements))
                if (this.timeObj) {
                  clearTimeout(this.timeObj)
                }
                this.$router.push({
                  path: sourceurl
                })
              }).catch(() => {
                this.$router.push({
                  path: sourceurl
                })
              })
            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          that.$nextTick(() => {
            that.timeObj = setTimeout(() => {
              that.longPoll()
            }, 1000 * 1)
          })
        }
      })
    }
  },
  mounted() {
    this.initQrCode()
  }
}
</script>

<style lang="scss" scoped>
.containner {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/Login/bg-login.png") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login {
  &-solgan {
    padding-top: 14rem;
    text-align: center;
    font-size: 3.6rem;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(34, 172, 216, 0.75);
    font-weight: 500;
  }

  &-content {
    width: 112rem;
    height: 50rem;
    margin: 0 auto;
    margin-top: 6rem;
    border-radius: 0.5rem;
    display: flex;

    &-img {
      width: 61.5rem;
      height: 50rem;
      background: url("../../assets/Login/login-img.png");
      background-position: 2px 0px;
      background-repeat: no-repeat;
    }

    &-info {
      flex: 1;
      height: 50rem;
      background: #ffffff;
      border-radius: 0 1.4rem 1.4rem 0;
      padding-top: 4.9rem;

      &-toggle {
        width: 36.4rem;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
        padding-bottom: 1.9rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &-title {
          font-size: 2.4rem;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          flex: 1;
        }

        &-btn {
          width: 5.5rem;
          height: 5.5rem;
          a {
            display: inline-block;
            cursor: pointer;
          }
        }
      }

      &-form {
        width: 36.4rem;
        margin: 0 auto;
        margin-top: 3rem;
        .el-form-item {
          margin-bottom: 3rem;
        }

        .login-btn-submit {
          width: 36.4rem;
          height: 4.6rem;
          background: #0073bc;
          border-radius: 0.4rem;
          font-size: 1.8rem;
          color: #ffffff;
          font-weight: 500;
          text-align: center;
          line-height: 4.6rem;
          cursor: pointer;
          margin-top: 3rem;
        }

        &-tools {
          margin-top: -1.2rem;
          display: flex;

          &-rember {
            width: 10rem;
          }

          &-btns {
            flex: 1;
            text-align: right;
            a {
              display: inline-block;
              font-size: 1.4rem;
              color: #0073bc;
              font-weight: 400;
              cursor: pointer;
            }

            span {
              display: inline-block;
              color: #d8d8d8;
              margin-left: 1.5rem;
              margin-right: 1.5rem;
            }
          }
        }
      }
    }

    &-info {
      &-qrcode {
        width: 36.4rem;
        margin: 0 auto;
        margin-top: 3rem;

        &-info {
          text-align: center;
          position: relative;
          img {
            width: 16.8rem;
            height: 16.8rem;
          }

          .qrcode-refresh {
            width: 15.8rem;
            height: 16.8rem;
            position: absolute;
            background: rgba(255, 255, 255, 0.8);
            left: 10rem;
            cursor: pointer;
            padding-top: 51px;
            text-align: center;

            .refresh {
              width: 3.9rem;
              height: auto;
            }

            &-title {
              color: #000;
              font-size: 1.2rem;
              padding: 0.2rem;
            }
          }

          &-title {
            width: 16.8rem;
            height: 4rem;
            margin: 0 auto;
            margin-top: 1.5rem;
            font-size: 1.4rem;
            color: rgba(0, 0, 0, 0.65);
            text-align: center;
            font-weight: 400;
          }

          &-btn {
            margin-top: 1.4rem;
            a {
              font-size: 14px;
              color: #0073bc;
              font-weight: 400;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
